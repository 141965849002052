<template>
    <v-container fluid fill-height>
        <v-row class="justify-center align-center">
            <v-col cols="12">
                <h1 class="text-sm-h6 text-md-h4 text-center"
                    style="white-space: pre-line; color:var(--color-goldish-beg);  font-family: 'GESS',roboto,sans-serif !important;line-height: initial">
                    {{ 'مرحبا بكم في في الموقع الإلكتروني للقسائم التعليمية' }}</h1>
            </v-col>
            <v-col cols="10">
                <v-card elevation="2">
                    <v-container>
                        <h3 class="text-sm-h6 text-md-h5 text-center"
                            style="white-space: pre-line; color:red;  font-family: 'GESS',roboto,sans-serif !important;line-height: initial">
                            {{'تغيير كلمة المرور'}}<h3 v-if="!isForResetPassword">
                            {{' عند الدخول للمرة الأولى'}}
                        </h3> </h3>

                    </v-container>
                    <v-card-text>
                        <v-container class="d-flex flex-column" style=";justify-content: end;">
                            <v-container fluid>
                                <v-row>
                                    <v-col v-if="false && !isForResetPassword" cols="12" dense>
                                        <v-text-field v-model="idNumber" label="رقم السجل المدني"
                                                      autocomplete="username" readonly small></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field ref="passwordInput" type="password" v-model="password"
                                                      label="كلمة السر"
                                                      autocomplete="new-password"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field ref="confirmPasswordInput" type="password"
                                                      v-model="confirmPassword"
                                                      label="إعادة كلمة السر"
                                                      autocomplete="new-password"></v-text-field>
                                    </v-col>
                                    <v-row style="justify-content: space-evenly;">
                                        <v-btn class="signInBtn" style="width:fit-content" @click="resetMyPassword"
                                               v-ripple="{ class: `goldRipple` }">تحديث
                                        </v-btn>
                                    </v-row>
                                </v-row>
                            </v-container>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import logo from '@/assets/images/logo.png'

    export default {
        name: "ResetPasswordForTheFirstTime",
        data() {
            return {
                logo,
                idNumber: '',
                password: '',
                confirmPassword: '',
                showPassword: false,
                email:'',
                resetToken:'',
            }
        },
        computed: {
            ...mapGetters(['loggedUser']),
            isForResetPassword(){
                return this.$route.name == 'ResetPassword'
            }
        },
        methods: {
            ...mapActions(['logOut']),
            keyPressedUsername() {
                this.$refs.passwordInput.focus()
            },
            keyPressedPassword() {
                if (event.key == 'Enter') this.signIn()
            },
            async resetMyPassword() {

                await this.$postRequest(this.isForResetPassword? 'reset_password':'first_login', '', {
                    idNumber: this.idNumber,
                    password: this.password,
                    password_confirmation: this.confirmPassword,
                    token: this.resetToken,
                    email: this.email
                })
                this.logOut()
            },
            goToHome() {
                this.$router.replace({name: 'Home'})
            }
        },
        mounted() {
            if(!this.isForResetPassword) {
                this.idNumber = this.loggedUser.id_number
            }
            else{
                this.resetToken = this.$route.query.token
                this.email = this.$route.query.email
            }
        }
    }
</script>

<style scoped>
    .signInBtn {
        width: 100%;
        border-radius: 12px;
        color: white;
        justify-content: center;
        padding: 0.8rem 1.1rem;
    }
</style>
