<template>
    <v-container>
        <v-data-table
                :headers="headers"
                :items="studentsResults"
                :options.sync="pagination"
                :server-items-length="totalModels"
                :loading="loading"
                :footer-props="{
                    'items-per-page-options': paginationPageSizeOptions
                  }"
        >
            <template v-slot:top>
                <v-toolbar
                        flat
                >
                    <v-toolbar-title>نتائج التسكين</v-toolbar-title>
                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>
                    <v-text-field
                            class="mt-5"
                            label="البحث"
                            append-icon="mdi-magnify"
                            @click:append="search"
                            v-model="filters.name"
                            @keydown.enter="search"
                            dense
                            solo
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <DownloadButton icon="mdi-printer"
                                    :url="`${$baseUrl}get_students_results?isExcel=1&keyword=${filters.name}${filters.currentSchoolID?'&currentSchoolID='+filters.currentSchoolID:''}${filters.is_night_school !== null?'&is_night_school='+filters.is_night_school:''}`"/>
                    <v-dialog v-model="studentStudyStateDialog" fullscreen>
                        <v-card>
                            <v-card-text v-if="selectedStudentForShowingDesire">
                                <v-form ref="studentStudyForm" :lazy-validation="true">
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field :value="selectedStudentForShowingDesire.name"
                                                          label="اسم الطالب" readonly
                                                          disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field :value="selectedStudentForShowingDesire.id_number"
                                                          label="رقم الهوية" readonly
                                                          disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field :value="selectedStudentForShowingDesire.placement_state.name"
                                                          label="حالة القبول" readonly
                                                          disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field :value="selectedStudentForShowingDesire.current_school.name"
                                                          label="المدرسة المنقول منها" readonly
                                                          disabled></v-text-field>
                                        </v-col>
                                        <v-col v-if="isSchool" cols="12">
                                            <v-radio-group row label="مباشرة الطالب" v-model="selectedStudentForShowingDesire.study_continuting_state">
                                                <v-radio label="نعم" :value="1"></v-radio>
                                                <v-radio label="لا" :value="0"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeStudentStudyDialog"
                                        :loading="loading"
                                >
                                    اغلاق
                                </v-btn>
                                <v-btn v-if="isSchool" :loading="loading" color="blue floatedBtn" text
                                       @click="updateStudentStudyState">
                                    حفظ
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="studentDesireDialog" fullscreen>
                        <v-card>
                            <v-card-text v-if="selectedStudentForShowingDesire">
                                <v-form ref="studentForm" :lazy-validation="true">
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field :value="selectedStudentForShowingDesire.name"
                                                          label="اسم الطالب" readonly
                                                          disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field :value="selectedStudentForShowingDesire.current_school.name"
                                                          label="المدرسة الحالية" readonly
                                                          disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field
                                                    :value="selectedStudentForShowingDesire.transferred_to_school.name"
                                                    label="المدرسة المنقول اليها" readonly
                                                    disabled></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field v-model="selectedStudentForShowingDesire.coupon_number"
                                                          label="رقم القسيمة"></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-autocomplete
                                                    label="مدة الصرف"
                                                    item-text="name"
                                                    item-value="id"
                                                    :items="terms"
                                                    v-model="selectedStudentForShowingDesire.term_id"
                                                    :rules="[rules.required]"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col v-if="isCommittee" cols="12">
                                            <v-radio-group row label="حالة القبول" v-model="placementStateId">
                                                <v-radio label="قبول" :value="2"></v-radio>
                                                <v-radio label="رفض" :value="3"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider/>
                                        </v-col>
                                        <v-col v-if="isCommittee && placementStateId == 3" cols="12">
                                            <v-row>
                                                <v-col cols="12" sm="6" md="6">
                                                    <v-text-field
                                                            v-model="placementRejectionReason"
                                                            label="سبب الرفض"
                                                            :rules="[rules.required]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeStudentPlacementDialog"
                                        :loading="loading"
                                >
                                    اغلاق
                                </v-btn>
                                <v-btn v-if="isCommittee" :loading="loading" color="blue floatedBtn" text
                                       @click="updateStudentPlacementState">
                                    حفظ
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.couponNumber="{ item }">
                <h4><b>
                    {{item.terms.length? item.terms[0].pivot.abv:''}}
                </b></h4>
            </template>
            <template v-slot:item.term="{ item }">
                <h4><b>
                    {{item.terms.length? item.terms[0].name:''}}
                </b></h4>
            </template>
            <template v-slot:item.study_continuting_state="{ item }">
                <h4><b>
                    {{item.study_continuting_state? 'نعم':'لا'}}
                </b></h4>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                        v-if="isAdmin || (isSchool && filters.is_night_school == 0)"
                        small
                        class="mr-2"
                        @click="showStudentStudyStateDialog(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                        v-if="(isAdmin || isCommittee) && item.current_school && item.transferred_to_school"
                        small
                        class="mr-2"
                        @click="showStudentDesire(item)"
                >
                    mdi-eye
                </v-icon>
                <v-icon
                        v-if="(isSchool) && item.placement_state_id == 2 && filters.is_night_school == 1"
                        small
                        class="mr-2"
                        @click="printTransferNotice(item)"
                >
                    mdi-printer
                </v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import formValidation from "@/formValidation"
    import {mapGetters, mapActions} from 'vuex'
    import DownloadButton from "@/components/common/DownloadButton";

    export default {
        name: "StudentsList",
        components: {DownloadButton},
        data() {
            return {
                rules: formValidation.rules,
                terms: [],
                studentsResults: [],
                totalModels: null,
                loading: false,
                dialog: false,
                dialogDelete: false,
                studentDesireDialog: false,
                studentStudyStateDialog: false,
                selectedStudentForShowingDesire: null,
                pagination: {
                    page: 1,
                    rowsPerPage: 10,
                },
                headers: [
                    {
                        text: "اسم الطالب",
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {
                        text: "رقم الهوية",
                        align: 'start',
                        sortable: false,
                        value: 'id_number',
                    },
                    {
                        text: "المدرسة الحالية",
                        align: 'start',
                        sortable: false,
                        value: 'current_school.name',
                    },
                    {
                        text: "المدرسة المنقول اليها",
                        align: 'start',
                        sortable: false,
                        value: 'transferred_to_school.name',
                    },
                    {
                        text: "رقم القسيمة",
                        align: 'start',
                        sortable: false,
                        value: 'couponNumber',
                    },
                    {
                        text: "مدة الصرف",
                        align: 'start',
                        sortable: false,
                        value: 'term',
                    },
                    {
                        text: "حالة القبول",
                        align: 'start',
                        sortable: false,
                        value: 'placement_state.name',
                    },
                    {
                        text: "سبب الرفض",
                        align: 'start',
                        sortable: false,
                        value: 'rejection_reason',
                    },
                    {
                        text: "الافعال",
                        align: 'start',
                        sortable: false,
                        value: 'actions',
                    }
                ],
                editedIndex: -1,
                editedItem: {
                    id: -1
                },
                defaultItem: {
                    id: -1
                },
                filters: {
                    name: '',
                    currentSchoolID: null,
                    is_night_school: null
                },
                placementStateId: 2,
                placementRejectionReason: ''
            }
        },

        computed: {
            ...mapGetters(['isAdmin', 'isCommittee', 'loggedUser', 'isSchool', 'loggedUserSchool', 'paginationPageSizeOptions']),
        },
        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
            pagination: {
                handler(newPagination, oldPagination) {
                    this.getLists()
                },
                deep: true
            }
        },
        methods: {
            ...mapActions(['getLoggedUserSchool']),
            //put user object for editing and open the edit dialog
            //
            //
            editItem(item) {
                this.editedIndex = this.studentsResults.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },
            showStudentStudyStateDialog(item){
                this.selectedStudentForShowingDesire = item
                this.studentStudyStateDialog = true
            },
            showStudentDesire(item) {
                this.selectedStudentForShowingDesire = item
                this.selectedStudentForShowingDesire.term_id = this.selectedStudentForShowingDesire.terms.length ?
                    this.selectedStudentForShowingDesire.terms[0].id : null
                this.selectedStudentForShowingDesire.coupon_number = this.selectedStudentForShowingDesire.terms.length ?
                    this.selectedStudentForShowingDesire.terms[0].pivot.abv : null
                this.studentDesireDialog = true
            },

            //close the Edit/New dialog
            //
            //
            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeStudentPlacementDialog() {
                this.studentDesireDialog = false
                this.$nextTick(() => {
                    this.selectedStudentForShowingDesire = null
                })
            },
            closeStudentStudyDialog() {
                this.studentStudyStateDialog = false
                this.$nextTick(() => {
                    this.selectedStudentForShowingDesire = null
                })
            },

            //close the delete dialog
            //
            //
            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            async updateStudentStudyState(){
                try {
                    if (this.$refs.studentStudyForm.validate()) {
                        this.loading = true
                        await this.$putRequest('SetContinuingState', '', {
                            studentID: this.selectedStudentForShowingDesire.id,
                            studyState: this.selectedStudentForShowingDesire.study_continuting_state
                        })
                        await this.getLists()
                        this.closeStudentStudyDialog()
                    }
                } catch (error) {
                } finally {
                    this.loading = false
                }
            },
            async updateStudentPlacementState() {
                try {
                    if (this.$refs.studentForm.validate()) {
                        this.loading = true
                        await this.$putRequest('students', 'UpdatePlacementState', {
                            student_id: this.selectedStudentForShowingDesire.id,
                            placementStateId: this.placementStateId,
                            coupon_number: this.selectedStudentForShowingDesire.coupon_number,
                            term_id: this.selectedStudentForShowingDesire.term_id,
                            rejectionReason: this.placementStateId == 3 ? this.placementRejectionReason : ''
                        })
                        await this.getLists()
                        this.closeStudentPlacementDialog()
                    }
                } catch (error) {
                } finally {
                    this.loading = false
                }
            },
            search() {
                this.getLists()
            },
            async getLists() {
                try {
                    this.loading = true
                    const data = await this.$getRequest('get_students_results', '', {
                        keyword: this.filters.name,
                        page: this.pagination.page,
                        pageSize: this.pagination.itemsPerPage,
                        currentSchoolID: this.filters.currentSchoolID ? this.filters.currentSchoolID : undefined,
                        is_night_school: this.filters.is_night_school !== null ? this.filters.is_night_school : undefined
                    })
                    this.studentsResults = data.data.result.data
                    this.totalModels = data.data.result.total
                } catch (error) {

                } finally {
                    this.loading = false
                }
            },
            async getTerms() {
                const data = await this.$getRequest('GetTerms', '')
                this.terms = data.data.terms
            },
            printTransferNotice({id}) {
                window.open(`${this.$baseUrl.replace('/api', '')}print_student_notice?studentID=${id}&schoolID=${this.loggedUser.school_id}`)
            }
        },
        async mounted() {
            if (this.isSchool) {
                this.headers = this.headers.filter((header, index) => index != 7 && index != 5 && index != 4 && index != 2)
                this.filters.currentSchoolID = this.loggedUser.school_id
                await this.getLoggedUserSchool();
                this.filters.is_night_school = this.loggedUserSchool.school_category_id != 1 ? 1:0
                if(this.loggedUserSchool.school_category_id == 1) {
                    this.headers[2] = {
                        text: "المدرسة المنقول منها",
                        align: 'start',
                        sortable: false,
                        value: 'current_school.name',
                    }
                    this.headers = this.headers.filter((header, index) => index != 1)
                    this.headers.splice(3, 0, {
                        text: "مباشرة الطالب",
                        align: 'start',
                        sortable: false,
                        value: 'study_continuting_state',
                    });
                }
            } else {
                this.headers = this.headers.filter((header, index) => index != 1)
            }
            await this.getLists()
            this.getTerms()
        }
    }
</script>

<style scoped>

</style>
