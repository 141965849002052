import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import api from './plugins/API'
import './assets/global.css'
import DatetimePicker from 'vuetify-datetime-picker'
import moment from 'moment'

Vue.prototype.$getRequest = api.getRequest
Vue.prototype.$postRequest = api.postRequest
Vue.prototype.$putRequest = api.putRequest
Vue.prototype.$deleteRequest = api.deleteRequest
Vue.prototype.$baseUrl = api.baseUrl

Vue.config.productionTip = false

Vue.prototype.$moment = moment

// Vue.use(require('vue-pusher'), {
//   api_key: process.env.VUE_APP_PUSHER_KEY,
//   options: {
//     cluster: process.env.VUE_APP_PUSHER_CLUSTER,
//     encrypted: true,
//   }
// });

Vue.use(DatetimePicker)
export default new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
