<template>
    <v-app-bar app style="background-color: var(--color-goldish-beg); height:82px;" dark elevation="1">
        <v-app-bar-nav-icon v-if="shouldConsiderUserLoggedIn && loggedUser" @click="switchDrawer()"></v-app-bar-nav-icon>
        <!-- <NotificationsMenu v-if="loggedUser"/> -->
        <v-spacer />
        <div class="d-flex flex-column align-end">
            <v-img class="mt-4" :src="headerLogo" max-height="90" max-width="90" contain></v-img>
            <h5>
                الإدارة العامة للتعليم بمحافظة جدة
            </h5>
            <h6>
                الموقع الإ لكترو ني للقسائم التعليمية
            </h6>
        </div>
    </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NotificationsMenu from "@/components/NotificationsMenu"
import headerLogo from '@/assets/images/headerLogo.png'

export default {
    name: "AppHeader",
    components: { NotificationsMenu },
    computed: {
        ...mapGetters(['shouldConsiderUserLoggedIn', 'loggedUser', 'isAdmin'])
    },
    data() {
        return {
            headerLogo
        }
    },
    methods: {
        ...mapActions(['switchDrawer']),
    }
}
</script>

<style scoped>
div.v-toolbar__content {
    align-items: center !important;
}

.logo {
    transition: transform 200ms ease-in-out;
    cursor: pointer;
}

.logo:hover {
    transform: scaleX(1.2);
}
</style>
