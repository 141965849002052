<template>
    <v-card>
       <v-card-text>
           <TextDivider text="بيانات الطالب"/>
           <v-row>
               <v-col
                       cols="12"
                       sm="6"
                       md="6"
               >
                   <v-text-field
                           readonly
                           disabled
                           :value="student.name"
                           name="name"
                           autocomplete="false"
                           label="اسم الطالب"
                   ></v-text-field>
               </v-col>
               <v-col
                       cols="12"
                       sm="6"
                       md="6"
               >
                   <v-text-field
                           readonly
                           disabled
                           :value="student.id_number"
                           name="idNumber"
                           autocomplete="false"
                           label="رقم السجل المدني"
                   ></v-text-field>
               </v-col>
               <v-col
                       cols="12"
               >
                   <v-text-field
                           readonly
                           disabled
                           :value="student.transferred_to_school?student.transferred_to_school.name:'يرجى إنتظار صدور النتيجة'"
                           autocomplete="false"
                           label="المدرسة المنقول إليها"
                   ></v-text-field>
               </v-col>
               <v-col cols="12">
                   <h3 class="red--text">سيتم تسليم الطالب اشعار القبول من المدرسة الحالية</h3>
               </v-col>
           </v-row>
       </v-card-text>
    </v-card>
</template>

<script>
    import TextDivider from "@/components/common/TextDivider";
    export default {
        name: "StudentResult",
        components:{TextDivider},
        props:{
            student:{
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
