<template>
    <v-btn @click="downloadClicked">
        <v-icon>{{icon}}</v-icon>
    </v-btn>
</template>

<script>
    export default {
        name: "DownloadButton",
        props:{
            icon:{
                default: 'mdi-download',
                type: String
            },
            url:{
                default: '',
                type: String
            }
        },
        methods:{
            downloadClicked(){
                window.open(this.url, '_blank')
            }
        }
    }
</script>

<style scoped>

</style>
