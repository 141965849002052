import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import SignIn from "@/views/auth/SignIn"
import ResetPasswordForTheFirstTime from "@/views/auth/ResetPasswordForTheFirstTime";
import Home from "@/views/Home"
import hoodsList from "@/views/hoods/hoodsList";
import studentsList from "@/views/students/studentsList";
import Users from "@/views/users/Users"
import officesList from "@/views/offices/officesList";
import schoolsList from "@/views/schools/schoolsList";

Vue.use(VueRouter)

const routes = [
    {
        path: '/SignIn',
        name: 'SignIn',
        component: SignIn
    },
    {
        path: '/ResetPasswordForTheFirstTime',
        name: 'ResetPasswordForTheFirstTime',
        component: ResetPasswordForTheFirstTime
    },
    {
        path: '/ResetPassword',
        name: 'ResetPassword',
        component: ResetPasswordForTheFirstTime
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home
    },
    {
        path: '/Home',
        name: 'Grades',
        component: Home
    },
    {
        path: '/Hoods',
        name: 'Hoods',
        component: hoodsList
    },
    {
        path: '/Offices',
        name: 'Offices',
        component: officesList
    },
    {
        path: '/Schools',
        name: 'Schools',
        component: schoolsList
    },
    {
        path: '/StudentsResults',
        name: 'StudentsResults',
        component: studentsList
    },
    {
        path: '/Users',
        name: 'Users',
        component: Users
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    let loggedUser = localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_USER_KEY)
    let token = localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_USER_TOKEN_KEY)
    if(!to.name){
        next({name:'Home'})
    }
    if (to.name == 'ResetPassword') {
        next()
    } else {
        if (to.name == 'ResetPasswordForTheFirstTime' && loggedUser) {
            loggedUser = JSON.parse(loggedUser)
            store.commit('setLoggedUser', loggedUser)
            store.commit('setUserToken', token)
            if(loggedUser.is_first_login) next()
            else next({name: 'SignIn'})
        } else {
            if (to.name == 'SignIn') {
                if (loggedUser) next({name: 'Home'})
                else next()
            } else {
                if (token) {
                    store.commit('setUserToken', token)
                    try {
                        const data = await store._vm.$getRequest('me', '')
                        if (data.data) {
                            store.commit('setLoggedUser', data.data.user)
                            if(data.data.user.is_first_login) next({name:'ResetPasswordForTheFirstTime'})
                            else next()
                        }
                    } catch {
                        store.commit('setLoggedUser', null)
                        store.commit('setUserToken', null)
                        next({name: 'SignIn'})
                    }

                } else {
                    next({name: 'SignIn'})
                }
            }
        }
    }
})

export default router
