<template>
    <v-container>
        <UserSchool v-if="isSchool && userSchool" :pSchool="userSchool" :key="userSchoolKey" @schoolUpdated="getUserSchool"/>
        <UserStudent v-if="isStudent && userStudent" :student="userStudent" :key="userStudentKey" @studentUpdated="getUserStudent"/>
    </v-container>
</template>

<script>
    import UserSchool from "@/views/schools/UserSchool";
    import UserStudent from "@/views/students/UserStudent";
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: "Home",
        components: {UserStudent, UserSchool},
        data: () => ({
            userSchool: null,
            userStudent: null,
            userSchoolKey: 0,
            userStudentKey: 0
        }),
        computed: {
            ...mapGetters(['isAdmin', 'isSchool', 'isStudent', 'isCommittee', 'loggedUserSchool', 'loggedUserStudent']),
        },
        methods: {
            ...mapActions(['getLoggedUserSchool', 'getLoggedUserStudent']),
            async getUserSchool() {
                await this.getLoggedUserSchool()
                this.userSchool = this.loggedUserSchool
                this.userSchoolKey++
            },
            async getUserStudent(){
                await this.getLoggedUserStudent()
                this.userStudent = this.loggedUserStudent
                this.userStudentKey++
            }
        },
        mounted() {
            if (this.isSchool) {
                this.getUserSchool()
            }
            else if(this.isStudent){
                this.getUserStudent()
            }
            else if(this.isCommittee){
                this.$router.replace({name: 'Schools'})
            }
        }
    }
</script>

<style scoped></style>
