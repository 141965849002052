<template>
    <v-navigation-drawer v-model="drawer" right app style="background-color: var(--color-brown)" dark>
        <v-img :aspect-ratio="16 / 9" :src="logo" class="mx-5 mt-2"/>
        <v-divider/>
        <v-list-item v-if="loggedUser != null">
            <v-list-item-content>
                <!--                <v-list-item-title>{{ isAdmin ? loggedUser.role.name : loggedUser.building ? loggedUser.building.name :-->
                <!--                    loggedUser.role.name }}</v-list-item-title>-->
                <v-list-item-title>{{loggedUser.display_name}}</v-list-item-title>
                <v-list-item-subtitle>{{loggedUser.email}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
                <v-btn v-if="shouldConsiderUserLoggedIn" icon text @click="logOut">
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
            </v-list-item-icon>
        </v-list-item>
        <v-divider/>
        <v-list dense nav>
            <v-list-item link
                         v-for="(userFunction, ufIndex) in userFunctions.filter(uf => !uf.routes || uf.routes.length == 0)"
                         :key="ufIndex" v-if="isUserPermitted(userFunction.permissions, userFunction.linkName)"
                         :to="{ name: userFunction.linkName }" exact>
                <v-list-item-action>
                    <v-icon>{{ userFunction.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ userFunction.content }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import logo from '@/assets/images/logo.png'

    export default {
        name: "SideMenu",
        data() {
            return {
                logo,
                rules: {},
                dialog: false
            }
        },
        computed: {
            ...mapGetters([
                'shouldConsiderUserLoggedIn',
                'loggedUser',
                'isAdmin',
                'isCommittee',
                'isSchool'
            ]),
            drawer: {
                get() {
                    return this.$store.state.drawer
                },
                set() {
                }
            },
            userFunctions() {
                return [
                    {
                        linkName: `Grades`,
                        content: 'الصفوف',
                        icon: 'mdi-garage',
                        permissions: ['School']
                    },
                    // {
                    //     linkName: `Users`,
                    //     content: 'المستخدمون',
                    //     icon: 'mdi-account',
                    //     permissions: ['SuperAdmin']
                    // },
                    {
                        linkName: `Hoods`,
                        content: 'الأحياء',
                        icon: 'mdi-road',
                        permissions: ['SuperAdmin']
                    },
                    {
                        linkName: `Offices`,
                        content: 'المكاتب',
                        icon: 'mdi-office-building-outline',
                        permissions: ['SuperAdmin']
                    },
                    {
                        linkName: `Schools`,
                        content: 'المدارس',
                        icon: 'mdi-school',
                        permissions: ['SuperAdmin', 'EducationCommittee']
                    },
                    {
                        linkName: `StudentsResults`,
                        content: 'نتائج التسكين',
                        icon: 'mdi-flare',
                        permissions: ['SuperAdmin', 'EducationCommittee']
                    },
                    {
                        linkName: `StudentsResults`,
                        content: 'طلابي',
                        icon: 'mdi-account',
                        permissions: ['School']
                    }
                ]
            },
        },
        methods: {
            ...mapActions(['logOut']),
            isUserPermitted(permissions, linkName) {
                for (let p = 0; p < permissions.length; p++) {
                    let permission = permissions[p]
                    if (permission == 'All') return true
                    if (permission == 'SuperAdmin' && this.isAdmin) return true
                    if (permission == 'EducationCommittee' && this.isCommittee) return true
                    if (permission == 'School' && this.isSchool) return true
                }
                return false
            },
            closeSettings() {
                this.dialog = false
            }
        }
    }
</script>

<style scoped></style>
