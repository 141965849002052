<template>
    <v-container>
        <v-data-table
                :headers="headers"
                :items="schools"
                :options.sync="pagination"
                :server-items-length="totalModels"
                :loading="loading"
                :footer-props="{
                    'items-per-page-options': paginationPageSizeOptions
                  }"
        >
            <template v-slot:top>
                <v-toolbar
                        flat
                >
                    <v-toolbar-title>المدارس</v-toolbar-title>
                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>
                    <v-text-field
                            class="mt-5"
                            label="البحث"
                            append-icon="mdi-magnify"
                            @click:append="search"
                            v-model="filters.name"
                            @keydown.enter="search"
                            dense
                            solo
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog
                            v-model="dialog"
                            max-width="1200px"
                    >
                        <!--                        <template v-slot:activator="{ on, attrs }">-->
                        <!--                            <v-btn-->
                        <!--                                    class="mb-2 floatedBtn"-->
                        <!--                                    v-bind="attrs"-->
                        <!--                                    v-on="on"-->
                        <!--                                    v-if="isAdmin"-->
                        <!--                            >-->
                        <!--                                مدرسة جديدة-->
                        <!--                            </v-btn>-->
                        <!--                        </template>-->
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                                <v-spacer/>
                                <v-icon>mdi-school</v-icon>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-form ref="schoolsForm" :lazy-validation="true">
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.name"
                                                        name="name"
                                                        autocomplete="false"
                                                        label="الاسم"
                                                        :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.mobile"
                                                        name="mobile"
                                                        autocomplete="false"
                                                        label="الهاتف"
                                                        :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.email"
                                                        name="email"
                                                        autocomplete="false"
                                                        label="البريد الالكتروني"
                                                        :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.abv"
                                                        name="abv"
                                                        autocomplete="false"
                                                        label="الرمز"
                                                        :rules="[rules.required]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.address1"
                                                        name="address1"
                                                        autocomplete="false"
                                                        label="العنوان الاول"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-text-field
                                                        v-model="editedItem.address2"
                                                        name="address2"
                                                        autocomplete="false"
                                                        label="العنوان الثاني"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-textarea
                                                        v-model="editedItem.notes"
                                                        name="notes"
                                                        rows="2"
                                                        autocomplete="false"
                                                        label="ملاحظات"
                                                ></v-textarea>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-autocomplete
                                                        label="وقت الدوام"
                                                        item-text="name"
                                                        item-value="id"
                                                        :items="schoolsTimes"
                                                        v-model="editedItem.school_time_id"
                                                        :rules="[rules.required]"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-autocomplete
                                                        label="النوع"
                                                        item-text="name"
                                                        item-value="id"
                                                        :items="educationTypes"
                                                        v-model="editedItem.education_type_id"
                                                        :rules="[rules.required]"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-autocomplete
                                                        label="الصنف"
                                                        item-text="name"
                                                        item-value="id"
                                                        :items="schoolCategories"
                                                        v-model="editedItem.school_category_id"
                                                        :rules="[rules.required]"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-autocomplete
                                                        label="الحي"
                                                        item-text="name"
                                                        item-value="id"
                                                        :items="hoods"
                                                        v-model="editedItem.hood_id"
                                                        :rules="[rules.required]"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6"
                                            >
                                                <v-autocomplete
                                                        label="المكتب"
                                                        item-text="name"
                                                        item-value="id"
                                                        :items="offices"
                                                        v-model="editedItem.office_id"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                            >
                                                <v-autocomplete
                                                        label="الصفوف"
                                                        item-text="name"
                                                        item-value="id"
                                                        :items="grades"
                                                        v-model="editedItem.grades"
                                                        :rules="[rules.required]"
                                                        multiple
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="close"
                                        :loading="loading"
                                >
                                    اغلاق
                                </v-btn>
                                <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="save"
                                        :loading="loading"
                                >
                                    حفظ
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">هل انت متاكد من الحذف؟</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">لا</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">نعم</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog v-model="schoolGradesDialog" fullscreen>
                        <v-card>
                            <v-card-text>
                                <UserSchool v-if="selectedSchoolForShowingGrades"
                                            :pSchool="selectedSchoolForShowingGrades"/>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="closeSchoolGrade"
                                        :loading="loading"
                                >
                                    اغلاق
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                        v-if="isAdmin"
                        small
                        class="mr-2"
                        @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                        v-if="isAdmin || isCommittee"
                        small
                        class="mr-2"
                        @click="showSchoolGradesList(item)"
                >
                    mdi-eye
                </v-icon>
                <v-icon
                        v-if="isAdmin"
                        small
                        @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
    import formValidation from "@/formValidation"
    import {mapGetters} from 'vuex'
    import UserSchool from "@/views/schools/UserSchool";

    export default {
        name: "SchoolsList",
        components: {UserSchool},
        data() {
            return {
                rules: {},
                schools: [],
                educationTypes: [],
                grades: [],
                hoods: [],
                offices: [],
                schoolCategories: [],
                schoolsTimes: [],
                totalModels: null,
                loading: false,
                dialog: false,
                dialogDelete: false,
                schoolGradesDialog: false,
                selectedSchoolForShowingGrades: null,
                pagination: {
                    page: 1,
                    rowsPerPage: 10,
                },
                headers: [
                    {
                        text: "#",
                        align: 'start',
                        sortable: false,
                        value: 'abv',
                    },
                    {
                        text: "الاسم",
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },
                    {
                        text: "الهاتف",
                        align: 'start',
                        sortable: false,
                        value: 'mobile',
                    },
                    {
                        text: "الدوام",
                        align: 'start',
                        sortable: false,
                        value: 'school_time.name',
                    },
                    {
                        text: "النوع",
                        align: 'start',
                        sortable: false,
                        value: 'school_category.name',
                    },
                    {
                        text: "الحي",
                        align: 'start',
                        sortable: false,
                        value: 'hood.name',
                    },
                    {
                        text: "المكتب",
                        align: 'start',
                        sortable: false,
                        value: 'name',
                    },

                    {
                        text: "الافعال",
                        align: 'start',
                        sortable: false,
                        value: 'actions',
                    }
                ],
                editedIndex: -1,
                editedItem: {
                    id: -1,
                    name: '',
                    abv: '',
                    mobile: '',
                    email: '',
                    address1: '',
                    address2: '',
                    lat: '',
                    long: '',
                    notes: '',
                    school_time_id: null,
                    education_type_id: null,
                    school_category_id: null,
                    office_id: null,
                    hood_id: null,
                    grades: []
                },
                defaultItem: {
                    id: -1,
                    name: '',
                    abv: '',
                    mobile: '',
                    email: '',
                    address1: '',
                    address2: '',
                    lat: '',
                    long: '',
                    notes: '',
                    school_time_id: null,
                    education_type_id: null,
                    school_category_id: null,
                    office_id: null,
                    hood_id: null,
                    grades: []
                },
                filters: {
                    name: ''
                }
            }
        },

        computed: {
            ...mapGetters(['isAdmin', 'isCommittee', 'loggedUser', 'paginationPageSizeOptions']),
            formTitle() {
                return this.editedIndex === -1 ? "مدرسة جديدة" : "تعديل المدرسة"
            }
        },
        watch: {
            dialog(val) {
                val || this.close()
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
            pagination: {
                handler(newPagination, oldPagination) {
                    this.getLists()
                },
                deep: true
            }
        },
        methods: {
            //put user object for editing and open the edit dialog
            //
            //
            editItem(item) {
                this.editedIndex = this.schools.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            showSchoolGradesList(item) {
                this.selectedSchoolForShowingGrades = item
                this.schoolGradesDialog = true
            },

            //put user object for delete and open the delete dialog
            //
            //
            deleteItem(item) {
                this.editedIndex = this.schools.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            //delete the item and request to server
            //
            //
            async deleteItemConfirm() {
                await this.$deleteRequest('schools', '', {id: this.editedItem.id})
                this.schools.splice(this.editedIndex, 1)
                this.closeDelete()
            },

            //close the Edit/New dialog
            //
            //
            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            closeSchoolGrade() {
                this.schoolGradesDialog = false
                this.$nextTick(() => {
                    this.selectedSchoolForShowingGrades = null
                })
            },

            //close the delete dialog
            //
            //
            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                })
            },
            //user save function
            //
            //
            async save() {
                try {
                    this.rules = formValidation.rules
                    if (this.$refs.schoolsForm.validate()) {
                        this.loading = true
                        if (this.editedIndex > -1) {
                            let data = await this.$putRequest('schools', this.editedItem.id, {
                                ...this.editedItem,
                                password: this.editedItem.abv,
                                role: 'School',
                                display_name: this.editedItem.name
                            })
                            Object.assign(this.schools[this.editedIndex], data)
                        } else {
                            let data = await this.$postRequest('schools', '', {
                                ...this.editedItem,
                                id: undefined,
                                password: this.editedItem.abv,
                                role: 'School',
                                display_name: this.editedItem.name
                            })
                            this.schools.push(data)
                        }
                        await this.getLists()
                        this.close()
                    }
                } catch (error) {
                } finally {
                    this.loading = false
                }
            },
            search() {
                this.getLists()
            },
            async getLists() {
                try {
                    this.loading = true
                    const
                        data = await this.$getRequest('schools', '', {
                            name: this.filters.name,
                            page: this.pagination.page,
                            pageSize: this.pagination.itemsPerPage
                        })
                    const {
                        educationTypes,
                        grades,
                        hoods,
                        offices,
                        schoolCategories,
                        schools,
                        schoolsTimes
                    } = data.data
                    this.educationTypes = educationTypes
                    this.grades = grades
                    this.offices = offices
                    this.hoods = hoods
                    this.schoolCategories = schoolCategories
                    this.schoolsTimes = schoolsTimes
                    this.schools = schools.data
                    this.totalModels = schools.total
                } catch (error) {

                } finally {
                    this.loading = false
                }
            }
        },
        async mounted() {
            await this.getLists()
        }
    }
</script>

<style scoped>

</style>
