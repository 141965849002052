<template>
    <v-container fluid fill-height>
        <v-row class="justify-center align-center">
            <v-col cols="12">
                <h1 class="text-sm-h6 text-md-h4 text-center"
                    style="white-space: pre-line; color:var(--color-goldish-beg);  font-family: 'GESS',roboto,sans-serif !important;line-height: initial">
                    {{ 'مرحبا بكم في في الموقع الإلكتروني للقسائم التعليمية' }}</h1>
            </v-col>
            <v-col cols="10">
                <v-card elevation="2">
                    <v-container>
                        <h3 class="text-sm-h6 text-md-h5 text-center"
                            style="white-space: pre-line; color:red;  font-family: 'GESS',roboto,sans-serif !important;line-height: initial">
                            {{ 'كتابة رقم السجل المدني للطالب أو منسوبي إدارة التعليم أو الرقم الوزاري للمدرسة في خانة
                            اسم
                            المستخدم و كلمة السر' }}</h3>
                    </v-container>
                    <v-card-text>
                        <v-container class="d-flex flex-column" style=";justify-content: end;">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" dense>
                                        <v-text-field v-model="username" label="اسم المستخدم" autocomplete="username"
                                                      @keypress.enter="keyPressedUsername()" small></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field ref="passwordInput" type="password" v-model="password"
                                                      label="الرمز السري" @keypress.enter="keyPressedPassword()"
                                                      autocomplete="new-password"></v-text-field>
                                    </v-col>
                                    <v-row style="justify-content: space-evenly;">
                                        <v-btn :loading="loading" class="signInBtn" style="width:fit-content"
                                               @click="signIn"
                                               v-ripple="{ class: `goldRipple` }">تسجيل
                                            الدخول
                                        </v-btn>
                                        <v-btn :loading="loading" class="signInBtn" style="width:fit-content"
                                               @click="showForgotMyPassword"
                                               v-ripple="{ class: `goldRipple` }">نسيت كلمة المرور
                                        </v-btn>
                                    </v-row>
                                </v-row>
                            </v-container>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-model="forgotMyPasswordDialog" max-width="50%">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">استعادة كلمة السر</span>
                        <v-spacer/>
                        <v-icon>mdi-key</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" dense>
                                <v-text-field v-model="forgotMyPassword.email" label="البريد الالكتروني"
                                              autocomplete="username" small></v-text-field>
                            </v-col>
                            <v-col>
                                <h4
                                        style="white-space: pre-line; color:red;  font-family: 'GESS',roboto,sans-serif !important;line-height: initial">
                                    {{'سيتم ارسال رابط استعادة كلمة السر على البريد الالكتروني المسجل للطالب في نظام
                                    نور.\n نأمل كتابته بشكل صحيح و التأكد من صفحة البريد الغير مرغوب به'}}</h4>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="sendForgotMyPasswordRequest">
                            ارسال
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="hideForgotMyPassword">
                            اغلاق
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="forgotMyPasswordEmailSentDialog" max-width="50%">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">استعادة كلمة السر</span>
                        <v-spacer/>
                        <v-icon>mdi-key</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" dense>
                                <h4 style="white-space: pre-line; color:red;  font-family: 'GESS',roboto,sans-serif !important;line-height: initial">
                                    {{'تم ارسال رابط لاعادة تعيين كلمة السر'}}
                                </h4>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="hideEmailSent">
                            اغلاق
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex'
    import logo from '@/assets/images/logo.png'

    export default {
        name: "SignIn",
        data() {
            return {
                logo,
                username: '',
                password: '',
                loading: false,
                showPassword: false,
                forgotMyPasswordDialog: false,
                forgotMyPasswordEmailSentDialog: false,
                forgotMyPassword: {
                    email: ''
                }
            }
        },
        computed: {
            ...mapGetters(['loggedUser'])
        },
        methods: {
            keyPressedUsername() {
                this.$refs.passwordInput.focus()
            },
            keyPressedPassword() {
                if (event.key == 'Enter') this.signIn()
            },
            async signIn() {
                try {
                    this.loading = true
                    await this.$store.dispatch('signIn', {
                        username: this.username,
                        password: this.password,
                        callback: this.goToHome,
                        firstLoginCallback: this.goToResetPasswordForTheFirstTime
                    })
                } catch (error) {
                    if (error.response.status == 404) {
                        this.text = error.response.data
                        this.snackbar = true
                    }
                } finally {
                    this.loading = false
                }
            },
            goToHome() {
                this.$router.replace({name: 'Home'})
            },
            goToResetPasswordForTheFirstTime() {
                this.$router.replace({name: 'ResetPasswordForTheFirstTime'})
            },
            async sendForgotMyPasswordRequest() {
                await this.$postRequest('reset_password_link', '', {
                    email: this.forgotMyPassword.email
                })
                this.hideForgotMyPassword()
                this.showEmailSent()
            },
            showEmailSent() {
                this.forgotMyPasswordEmailSentDialog = true;
            },
            hideEmailSent() {
                this.forgotMyPasswordEmailSentDialog = false;
            },
            showForgotMyPassword() {
                this.forgotMyPasswordDialog = true
            },
            hideForgotMyPassword() {
                this.forgotMyPasswordDialog = false
            }
        }
    }
</script>

<style scoped>
    .signInBtn {
        width: 100%;
        border-radius: 12px;
        color: white;
        justify-content: center;
        padding: 0.8rem 1.1rem;
    }
</style>
