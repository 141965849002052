<template>
    <v-app style="background: #F1EDE9" dark class="myApp">
        <AppHeader/>
        <sideMenu v-if="shouldConsiderUserLoggedIn && loggedUser"/>
        <v-main class="mt-5">
            <router-view/>
            <v-snackbar v-model="snackbar" :color="snackColor" :timeout="3500" bottom>
                <h5 style="color: white">
                    {{ text }}
                </h5>

                <template v-slot:action="{ attrs }">
                    <v-btn color="white" text v-bind="attrs" @click="snackbar = false" style="color:white !important;">
                        اغلاق
                    </v-btn>
                </template>
            </v-snackbar>
        </v-main>
    </v-app>
</template>

<script>
    import AppHeader from "@/components/AppHeader"
    import sideMenu from "@/components/sideMenu"
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'App',
        components: {AppHeader, sideMenu},
        data() {
            return {
                snackbar: false,
                text: '',
                snackColor: 'success'
            }
        },
        computed: {
            ...mapGetters(['shouldConsiderUserLoggedIn', 'loggedUser'])
        },
        methods: {
            showMessage({
                            message,
                            color = 'success'
                        }) {
                this.text = message
                this.snackColor = color
                this.snackbar = true
            }
        }
    };
</script>
<style>
    @font-face {
        font-family: "GESS";
        src: local("GESS"),
        url(./assets/fonts/ArbFONTS-GE-SS-Two-Bold.otf) format("truetype");
    }

    * {
        font-family: 'GESS', roboto, sans-serif !important;
    }

    .hovering {
        transition: all 0.2s ease-in-out;
    }

    .hovering:hover {
        transform: scale(1.03);
        cursor: pointer;
    }
</style>
