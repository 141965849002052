<template>
    <v-container v-if="school">
        <v-data-table :headers="headers" :items="schoolGrades">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>الصفوف</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" fullscreen>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mb-2 floatedBtn" v-bind="attrs" v-on="on" v-if="isSchool">
                                تحديث معلومات المدرسة
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">تحديث معلومات المدرسة</span>
                                <v-spacer/>
                                <v-icon>mdi-school</v-icon>
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="schoolsForm" :lazy-validation="true">
                                    <v-row>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-text-field
                                                    readonly
                                                    disabled
                                                    :value="school.name"
                                                    name="name"
                                                    autocomplete="false"
                                                    label="الاسم"
                                                    :rules="[rules.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-text-field
                                                    readonly
                                                    disabled
                                                    :value="school.mobile"
                                                    name="mobile"
                                                    autocomplete="false"
                                                    label="الهاتف"
                                                    :rules="[rules.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-text-field
                                                    readonly
                                                    disabled
                                                    :value="school.email"
                                                    name="email"
                                                    autocomplete="false"
                                                    label="البريد الالكتروني"
                                                    :rules="[rules.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-text-field
                                                    readonly
                                                    disabled
                                                    :value="school.abv"
                                                    name="abv"
                                                    autocomplete="false"
                                                    label="الرمز"
                                                    :rules="[rules.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-text-field
                                                    v-model="school.address1"
                                                    name="address1"
                                                    autocomplete="false"
                                                    label="العنوان الاول"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-text-field
                                                    v-model="school.address2"
                                                    name="address2"
                                                    autocomplete="false"
                                                    label="العنوان الثاني"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-autocomplete
                                                    readonly
                                                    disabled
                                                    label="وقت الدوام"
                                                    item-text="name"
                                                    item-value="id"
                                                    :items="schoolsTimes"
                                                    :value="school.school_time_id"
                                                    :rules="[rules.required]"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-autocomplete
                                                    readonly
                                                    disabled
                                                    label="النوع"
                                                    item-text="name"
                                                    item-value="id"
                                                    :items="educationTypes"
                                                    :value="school.education_type_id"
                                                    :rules="[rules.required]"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-autocomplete
                                                    readonly
                                                    disabled
                                                    label="الصنف"
                                                    item-text="name"
                                                    item-value="id"
                                                    :items="schoolCategories"
                                                    :value="school.school_category_id"
                                                    :rules="[rules.required]"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-autocomplete
                                                    label="الحي"
                                                    item-text="name"
                                                    item-value="id"
                                                    :items="hoods"
                                                    v-model="school.hood_id"
                                                    :rules="[rules.required]"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="6"
                                        >
                                            <v-autocomplete
                                                    label="المكتب"
                                                    item-text="name"
                                                    item-value="id"
                                                    :items="offices"
                                                    v-model="school.office_id"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                        >
                                            <v-autocomplete
                                                    label="الصفوف"
                                                    item-text="name"
                                                    item-value="id"
                                                    return-object
                                                    :items="grades"
                                                    v-model="school.grades"
                                                    :rules="[rules.required]"
                                                    multiple
                                                    clearable
                                            >
                                                <template v-slot:item="{ item }">
                                                    {{ item.name }}
                                                    <v-list-item style="justify-content: end">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                    v-model="item.pivot.is_male"
                                                                    label="بنين"
                                                            ></v-checkbox>
                                                            <v-checkbox
                                                                    v-model="item.pivot.is_female"
                                                                    label="بنات"
                                                            ></v-checkbox>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12">
                                            <Map v-if="showMap" ref="mapPickerRef"
                                                 :initialLocation="school.lat && school.long?[school.lat, school.long]:null"/>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue floatedBtn" text @click="close" :loading="loading">
                                    اغلاق
                                </v-btn>
                                <v-btn color="blue floatedBtn" text @click="save" :loading="loading">
                                    حفظ
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon v-if="isSchool || isCommittee" small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
            </template>
        </v-data-table>
        <v-dialog v-if="school" v-model="vacancyDialog" fullscreen>
            <v-card>
                <v-card-title>
                    <span class="text-h5">الشواغر</span>
                    <v-spacer/>
                    <v-icon>mdi-account</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="vacancyForm" :lazy-validation="true">
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field v-model="school.name" label="اسم المدرسة" readonly
                                                  disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field :value="schoolGradeRecord.grade.education_level.name"
                                                  label="المرحلة الدراسية" readonly disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field :value="getGradeSectionTitle(schoolGradeRecord)" label="القطاع"
                                                  readonly disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field :value="schoolGradeRecord.grade.name" label="الصف" readonly
                                                  disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                            :value="schoolGradeRecord.LatestVacancyRequests?schoolGradeRecord.LatestVacancyRequests.male_vacancies_count:''"
                                            label="عدد الشواغر بنين" readonly
                                            disabled></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                            :value="schoolGradeRecord.LatestVacancyRequests?schoolGradeRecord.LatestVacancyRequests.female_vacancies_count:''"
                                            label="عدد الشواغر بنات" readonly
                                            disabled></v-text-field>
                                </v-col>
                                <v-col v-if="isSchool" cols="12">
                                    <v-radio-group row label="هل هناك اي تحديث للشواغر؟" v-model="isThereVacancyUpdate">
                                        <v-radio label="نعم" :value="1"></v-radio>
                                        <v-radio label="لا" :value="0"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider/>
                                </v-col>
                                <v-col v-if="isSchool && isThereVacancyUpdate" cols="12">
                                    <v-row>
                                        <v-col cols="12">
                                            <h3>في حال اختيار نعم</h3>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field
                                                    type="number"
                                                    v-model="new_male_vacancies_count"
                                                    label="عدد الشواغر بنين بعد التحديث"
                                                    :rules="[rules.required]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field type="number"
                                                          v-model="new_female_vacancies_count"
                                                          label="عدد الشواغر بنات بعد التحديث"
                                                          :rules="[rules.required]"></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <h4 class="red--text">في حال تم تغيير الشواغر يجب الموافقة عليها من قبل لجنة
                                                القسائم التعليمية</h4>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col v-if="isCommittee" cols="12">
                                    <v-radio-group row label="هل هناك اي تحديث للشواغر؟" v-model="vacancyStateId">
                                        <v-radio label="قبول" :value="2"></v-radio>
                                        <v-radio label="رفض" :value="3"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12">
                                    <v-divider/>
                                </v-col>
                                <v-col v-if="isCommittee && vacancyStateId == 3" cols="12">
                                    <v-row>
                                        <v-col cols="12" sm="6" md="6">
                                            <v-text-field
                                                    v-model="vacancyRejectReason"
                                                    label="سبب الرفض"
                                                    :rules="[rules.required]"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :loading="loading" color="blue floatedBtn" text @click="close">
                        اغلاق
                    </v-btn>
                    <v-btn v-if="isThereVacancyUpdate || isCommittee" :loading="loading" color="blue floatedBtn" text
                           @click="saveNewVacancies">
                        حفظ
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import formValidation from "@/formValidation"
    import Map from "@/components/common/Map";
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: "UserSchool",
        components: {Map},
        props: {
            pSchool: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                rules: {},
                educationTypes: [],
                grades: [],
                hoods: [],
                offices: [],
                schoolCategories: [],
                schoolsTimes: [],
                showMap: false,
                dialog: false,
                vacancyDialog: false,
                dialogDelete: false,
                loading: false,
                school: this.pSchool,
                vacancyStateId: 2,
                vacancyRejectReason: '',
                headers: [
                    {
                        text: 'المرحلة',
                        align: 'start',
                        sortable: false,
                        value: 'grade.education_level.name',
                    },
                    {
                        text: 'الصف',
                        align: 'start',
                        sortable: false,
                        value: 'grade.name',
                    },
                    {
                        text: 'عدد الشواغر بنين',
                        align: 'start',
                        sortable: false,
                        value: 'LatestVacancyRequests.male_vacancies_count',
                    },
                    {
                        text: 'عدد الشواغر بنات',
                        align: 'start',
                        sortable: false,
                        value: 'LatestVacancyRequests.female_vacancies_count',
                    },
                    {
                        text: 'حالة الشواغر',
                        align: 'start',
                        sortable: false,
                        value: 'LatestVacancyRequests.vacancy_state.name',
                    },
                    {
                        text: 'سبب الرفض',
                        align: 'start',
                        sortable: false,
                        value: 'LatestVacancyRequests.rejectionReason',
                    },
                    {
                        text: 'أدوات',
                        align: 'start',
                        sortable: false,
                        value: 'actions',
                    }
                ],
                editedIndex: -1,
                schoolGradeRecord: {
                    id: -1,
                    name: '',
                    grade: {
                        education_level: {}
                    },
                },
                defaultItem: {
                    id: -1,
                    name: ''
                },
                isThereVacancyUpdate: 0,
                new_female_vacancies_count: 0,
                new_male_vacancies_count: 0,
            }
        },
        computed: {
            ...mapGetters([
                'isAdmin',
                'isSchool',
                'isCommittee'
            ]),
            schoolGrades() {
                if (!this.school) return []
                return this.school.school_grades
            }
        },
        watch: {
            dialog(val) {
                val || this.close()
                this.$nextTick(() => this.showMap = val)
            },
            dialogDelete(val) {
                val || this.closeDelete()
            },
        },
        methods: {
            ...mapActions(['getLoggedUserSchool']),
            getGradeSectionTitle(schoolGradeRecord) {
                const {is_male, is_female} = schoolGradeRecord
                if (is_male && is_female) return "بنين و بنات"
                if (is_female) return "بنات"
                if (is_male) return "بنين"
                return ""
            },
            editItem(item) {
                this.schoolGradeRecord = Object.assign({}, item)
                this.new_female_vacancies_count = this.schoolGradeRecord.LatestVacancyRequests ? this.schoolGradeRecord.LatestVacancyRequests.female_vacancies_count : 0
                this.new_male_vacancies_count = this.schoolGradeRecord.LatestVacancyRequests ? this.schoolGradeRecord.LatestVacancyRequests.male_vacancies_count : 0
                this.vacancyDialog = true
            },
            close() {
                this.dialog = false
                this.vacancyDialog = false
            },
            async save() {
                try {
                    this.rules = formValidation.rules
                    if (this.$refs.schoolsForm.validate()) {
                        this.loading = true
                        const currentLocation = this.$refs.mapPickerRef.currentLocation
                        this.school.lat = currentLocation.lat ? currentLocation.lat : currentLocation[0];
                        this.school.long = currentLocation.lng ? currentLocation.lng : currentLocation[1]
                        await this.$putRequest('schools', this.school.id, {
                            ...this.school,
                            grades: this.school.grades.reduce((gradesObject, grade) => {
                                gradesObject[grade.id] = {
                                    is_female: grade.pivot.is_female,
                                    is_male: grade.pivot.is_male,
                                }

                                return gradesObject;
                            }, {}),
                            role: 'School',
                            display_name: this.school.name
                        })
                        await this.getLoggedUserSchool()
                        this.close()
                    }
                } catch (error) {
                } finally {
                    this.loading = false
                }
            },
            async saveNewVacancies() {
                try {
                    this.rules = formValidation.rules
                    if (this.$refs.vacancyForm.validate()) {
                        this.loading = true
                        if(this.isSchool) {
                            await this.$postRequest('grade_count', '', {
                                male_vacancies_count: this.new_male_vacancies_count,
                                female_vacancies_count: this.new_female_vacancies_count,
                                school_grade_id: this.schoolGradeRecord.id
                            })
                            this.$emit('schoolUpdated')
                        }
                        else if(this.isCommittee){
                            await this.$putRequest('schools', 'update_vacancy_state', {
                                vacancyId: this.schoolGradeRecord.LatestVacancyRequests.id,
                                stateId: this.vacancyStateId,
                                rejectionReason:  this.vacancyStateId == 3 ? this.vacancyRejectReason : ""
                            })
                            await this.getLocalLoggedUserSchool()
                        }
                        this.close()
                    }
                } catch (error) {
                } finally {
                    this.loading = false
                }
            },
            async getLists() {
                const data = await this.$getRequest('lists', '')
                const {
                    educationTypes,
                    grades,
                    hoods,
                    offices,
                    schoolCategories,
                    schoolsTimes
                } = data.data
                this.educationTypes = educationTypes
                this.grades = grades
                this.offices = offices
                this.hoods = hoods
                this.schoolCategories = schoolCategories
                this.schoolsTimes = schoolsTimes
            },
            async getLocalLoggedUserSchool() {
                if(this.isSchool)
                {
                    await this.getLoggedUserSchool()
                }
                else if(this.isCommittee){
                    const data = await this.$getRequest('schools', this.school.id)
                    this.school = data.data.school
                }
                this.grades = this.grades.map(grade => {
                    const schoolGrade = this.school.grades.find(schoolGrade => schoolGrade.id == grade.id)
                    return {
                        ...grade,
                        pivot: schoolGrade ? schoolGrade.pivot : {is_female: false, is_male: false}
                    }
                })
            }
        },
        async mounted() {
            await this.getLists()
            this.getLocalLoggedUserSchool()
        }
    }
</script>

<style scoped></style>
