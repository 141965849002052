import axios from 'axios'
import main from "@/main";
import store from '@/store'
// var baseUrl = `https://api.jed-ssa.com/api/`
// var baseUrl = `http://localhost:8000/api/`
// var baseUrl = `http://192.168.1.80:8000/api/`
var baseUrl = 'https://test.api.jed-ssa.com/api/'

export default {
    getRequest: async function (controllerName, urlPath, data) {
        try {
            let url = `${baseUrl}${controllerName}/${urlPath}`
            if (urlPath == '') url = url.slice(0, -1)
            let resp = await axios.get(url, {
                params: data,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_USER_TOKEN_KEY)}`
                }
            })
            return resp.data
        } catch (error) {
            console.log(error)
            if(error.response.status == 401){
                main.$router.replace({name: 'SignIn'})
            }
            main.$children[0].showMessage({
                message: error.response.data.message,
                color: 'error'
            })
            throw error
        }
    },
    postRequest: async function (controllerName, urlPath, data) {
        try {
            let url = `${baseUrl}${controllerName}/${urlPath}`
            if (urlPath == '') url = url.slice(0, -1)
            let resp = await axios.post(url, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_USER_TOKEN_KEY)}`
                }
            })
            return resp.data
        } catch (error) {
            console.log(error)
            if(error.response.status == 401){
                main.$router.replace({name: 'SignIn'})
            }
            main.$children[0].showMessage({
                message: error.response.data.message,
                color: 'error'
            })
            throw error
        }
    },
    putRequest: async function (controllerName, urlPath, data) {
        try {
            let url = `${baseUrl}${controllerName}/${urlPath}`
            if (urlPath == '') url = url.slice(0, -1)
            let resp = await axios.put(url, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_USER_TOKEN_KEY)}`
                }
            })
            return resp.data
        } catch (error) {
            console.log(error)
            if(error.response.status == 401){
                main.$router.replace({name: 'SignIn'})
            }
            main.$children[0].showMessage({
                message: error.response.data.message,
                color: 'error'
            })
            throw error
        }
    },
    deleteRequest: async function (controllerName, urlPath, data) {
        try {
            let url = `${baseUrl}${controllerName}/${urlPath}`
            if (urlPath == '') url = url.slice(0, -1)
            let resp = await axios.delete(url, {
                data,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE_USER_TOKEN_KEY)}`
                }
            })
            return resp.data
        } catch (error) {
            console.log(error)
            if(error.response.status == 401){
                main.$router.replace({name: 'SignIn'})
            }
            main.$children[0].showMessage({
                message: error.response.data.message,
                color: 'error'
            })
            throw error
        }
    },
    baseUrl
}
