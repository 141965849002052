<template>
    <v-container v-if="student && rules">
        <v-card v-if="!student.has_saved_before">
            <v-card-text>
                <v-form ref="studentForm" v-model="isFormValid">
                    <TextDivider text="البيانات الشخصية"/>
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    readonly
                                    disabled
                                    :value="student.name"
                                    name="name"
                                    autocomplete="false"
                                    label="اسم الطالب"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    readonly
                                    disabled
                                    :value="student.id_number"
                                    name="idNumber"
                                    autocomplete="false"
                                    label="رقم السجل المدني"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    readonly
                                    disabled
                                    :value="student.nationality"
                                    name="nationality"
                                    autocomplete="false"
                                    label="الجنسية"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    readonly
                                    disabled
                                    :value="student.parent_id_number"
                                    name="idNumber"
                                    autocomplete="false"
                                    label="رقم السجل المدني لولي الأمر"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-radio-group row label="هل لدى الطالب إخوة في مدرسة مسائية؟" v-model="isThereSibling">
                                <v-radio label="نعم" :value="1"></v-radio>
                                <v-radio label="لا" :value="0"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col v-if="isThereSibling" cols="12">
                            <v-row>
                                <v-col cols="12"
                                       sm="4"
                                       md="4">
                                    <h4>الرجاء إدخال أرقام السجلات المدنية للإخوة</h4>
                                </v-col>
                                <v-col cols="12">
                                    <v-data-table :headers="siblingsTableHeaders" :items="siblings">
                                        <template v-slot:top>
                                            <v-toolbar flat>
                                                <v-text-field
                                                        v-model="siblingIdNumber"
                                                        name="idNumber"
                                                        autocomplete="false"
                                                        label="كتابة رقم السجل المدني"
                                                ></v-text-field>
                                                <v-spacer/>
                                               <v-tooltip bottom>
                                                   <template v-slot:activator="{ on, attrs }">
                                                       <v-btn small text class="mr-2" v-bind="attrs"
                                                               v-on="on" @click="addSibling">
                                                           <v-icon>mdi-plus</v-icon>
                                                       </v-btn>
                                                   </template>
                                                   <span>اضافة رقم مدني</span>
                                               </v-tooltip>
                                            </v-toolbar>
                                        </template>
                                        <template v-slot:item.actions="{ item, index }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn small text class="mr-2" v-bind="attrs"
                                                           v-on="on" @click="deleteSibling(index)">
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>حذف الرقم المدني</span>
                                            </v-tooltip>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <TextDivider text="بيانات الاتصال"/>
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    v-model="student.mobile"
                                    name="name"
                                    autocomplete="false"
                                    label="رقم الجوال"
                                    :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    v-model="student.email"
                                    name="name"
                                    autocomplete="false"
                                    label="البريد الإلكتروني"
                                    :rules="[rules.required, rules.email]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <TextDivider text="البيانات التعليمية"/>
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    readonly
                                    disabled
                                    :value="student.current_school.name"
                                    name="name"
                                    autocomplete="false"
                                    label="المدرسة الحالية"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    readonly
                                    disabled
                                    :value="student.current_school.hood.name"
                                    name="name"
                                    autocomplete="false"
                                    label="موقع المدرسة"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    readonly
                                    disabled
                                    :value="student.default_education_level.name"
                                    name="name"
                                    autocomplete="false"
                                    label="المرحلة الدراسية"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-autocomplete
                                    label="الصف"
                                    item-text="name"
                                    item-value="id"
                                    :items="grades"
                                    :value="student.grade_id"
                                    :rules="[rules.required]"
                                    disabled
                                    readonly
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <TextDivider text="بيانات السكن"/>
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    v-model="student.national_address"
                                    name="hoodName"
                                    autocomplete="false"
                                    label="العنوان الوطني"
                                    :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-autocomplete
                                    label="الحي"
                                    item-text="name"
                                    item-value="id"
                                    :items="hoods"
                                    v-model="student.hood_id"
                                    :rules="[rules.required]"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    v-model="student.main_street"
                                    name="mainStreet"
                                    autocomplete="false"
                                    label="الشارع الرئيسي"
                                    :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-text-field
                                    v-model="student.sub_street"
                                    name="subStreet"
                                    autocomplete="false"
                                    label="الشارع الفرعي"
                                    :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <Map ref="mapPickerRef"
                                 :initialLocation="student.lat && student.long?[student.lat, student.long]:null"/>
                        </v-col>
                    </v-row>
                    <TextDivider text="الرغبات"/>
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-row>
                                <v-col v-for="desire in desires.filter((desire, index)=>index<5)" cols="12">
                                    <v-autocomplete
                                            :label="desire.text"
                                            item-text="name"
                                            item-value="id"
                                            :items="hoods"
                                            v-model="desire.value"
                                            :rules="[rules.required]"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="6"
                        >
                            <v-row>
                                <v-col v-for="desire in desires.filter((desire, index)=>index>=5)" cols="12">
                                    <v-autocomplete
                                            :label="desire.text"
                                            item-text="name"
                                            item-value="id"
                                            :items="hoods"
                                            v-model="desire.value"
                                            :rules="[rules.required]"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="1">
                            <v-checkbox
                                    class="mt-0"
                                    v-model="isPledged"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="11" style="color: red">
                            أتعهد أنا ولي أمر الطالب بما يلي :
                            <ul>
                                <li>إن جميع البيانات أعلاه صحيحة و تحت مسؤوليتي</li>
                                <li>عدم استلام أي مقابل مالي لتعليم أبنائي من أي جهة أخرى</li>
                                <li>الموافقة على نقل ابني إلى أي مدرسة في حال تعذر وجود شواغر في الأحياء المرغوبة</li>
                                <li>الموافقة على نقل ابني فوراً إلى مدرسة حكومية في حال توفر شاغر خلال العام الدراسي
                                </li>
                            </ul>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <h3>يتم تفعيل أيقونة الحفظ بعد تعبئة جميع البيانات و الرغبات و بعد الموافقة على التعهد</h3>
                <v-spacer/>
                <v-btn v-if="shouldEnableSave" color="blue floatedBtn" text @click="save" :loading="loading">
                    حفظ
                </v-btn>
            </v-card-actions>
        </v-card>
        <StudentResult v-if="student.has_saved_before" :student="student"/>
    </v-container>
</template>

<script>
    import formValidation from "@/formValidation"
    import Map from "@/components/common/Map";
    import {mapGetters, mapActions} from 'vuex'
    import TextDivider from "@/components/common/TextDivider";
    import StudentResult from "@/views/students/StudentResult";

    export default {
        name: "UserStudent",
        components: {StudentResult, TextDivider, Map},
        props: {
            student: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                rules: null,
                hoods: [],
                grades: [],
                siblings: [],
                loading: false,
                isFormValid: false,
                isThereSibling: 0,
                isPledged: 0,
                siblingIdNumber:'',
                siblingsTableHeaders: [
                    {
                        text: 'رقم السجل المدني',
                        align: 'start',
                        sortable: false,
                        value: 'idNumber',
                    },
                    {
                        text: '',
                        align: 'start',
                        sortable: false,
                        value: 'actions',
                    },
                ],
                desires: [
                    {
                        text: 'الرغبة الأولى',
                        value: null
                    },
                    {
                        text: 'الرغبة الثانية',
                        value: null
                    },
                    {
                        text: 'الرغبة الثالثة',
                        value: null
                    },
                    {
                        text: 'الرغبة الرابعة',
                        value: null
                    },
                    {
                        text: 'الرغبة الخامسة',
                        value: null
                    },
                    {
                        text: 'الرغبة السادسة',
                        value: null
                    },
                    {
                        text: 'الرغبة السابعة',
                        value: null
                    },
                    {
                        text: 'الرغبة الثامنة',
                        value: null
                    },
                    {
                        text: 'الرغبة التاسعة',
                        value: null
                    },
                    {
                        text: 'الرغبة العاشرة',
                        value: null
                    }
                ]
            }
        },
        computed: {
            ...mapGetters([
                'isAdmin',
                'isStudent'
            ]),
            shouldEnableSave(){
                return this.isPledged && this.isFormValid
            }
        },
        methods:{
            ...mapActions(['getLoggedUserStudent']),
            async save() {
                try {
                    if (this.$refs.studentForm.validate()) {
                        this.loading = true
                        const currentLocation = this.$refs.mapPickerRef.currentLocation
                        this.student.lat = currentLocation.lat ? currentLocation.lat : currentLocation[0];
                        this.student.long = currentLocation.lng ? currentLocation.lng : currentLocation[1]
                        await this.$postRequest('set_student_info', '', {
                            ...this.student,
                            has_sibilings: this.isThereSibling,
                            sibilings: !this.isThereSibling? [] : this.siblings.map(sibling=>sibling.idNumber),
                            desires: this.desires.map((desire, index)=>{
                                return {
                                    value: desire.value,
                                    sort: index + 1
                                }
                            })
                        })
                        location.reload()
                    }
                } catch (error) {
                } finally {
                    this.loading = false
                }
            },
            addSibling(){
                this.siblings.push({
                    idNumber:this.siblingIdNumber
                })
            },
            deleteSibling(index){
                this.siblings.splice(index, 1)
            },
            async getLists() {
                const data = await this.$getRequest('lists', '')
                const {
                    grades,
                    hoods,
                } = data.data
                this.grades = grades
                this.hoods = hoods
            },
            async getLocalLoggedUserStudent() {
                await this.getLoggedUserStudent()
            }
        },
        async mounted() {
            this.rules = formValidation.rules
            await this.getLists()
            this.getLocalLoggedUserStudent()
        }
    }
</script>

<style scoped></style>
