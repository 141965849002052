<template>
    <v-row wrap no-gutters class="mt-5 pt-5">
        <v-col cols="1" class="text-center">
            <v-divider class="mt-3" />
        </v-col>
        <v-col cols="2" class="text-center text-h6">
            {{text}}
        </v-col>
        <v-col cols="9" class="text-center">
            <v-divider class="mt-3" />
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "TextDivider",
        props:{
            text:{
                type: String,
                default:''
            }
        }
    }
</script>

<style scoped>

</style>
