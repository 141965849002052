import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

Vue.use(Vuex)
const ROLE_SUPERADMIN = 1
const ROLE_SCHOOL = 2
const ROLE_STUDENT = 3
const ROLE_EDUCATION_COMMITTEE = 4

export default new Vuex.Store({
    state: {
        drawer: true,
        loggedUser: null,
        userSchool: null,
        userStudent: null,
        paginationPageSizeOptions: [10, 25, 50]
    },
    mutations: {
        setLoggedUser(state, user) {
            state.loggedUser = user
            if (user) {
                localStorage.setItem(process.env.VUE_APP_LOCALSTORAGE_USER_KEY, JSON.stringify(user))
            } else {
                localStorage.removeItem(process.env.VUE_APP_LOCALSTORAGE_USER_KEY)
            }
        },
        setLoggedUserSchool(state, school) {
            state.userSchool = school
        },
        setLoggedUserStudent(state, student) {
            state.userStudent = student
        },
        setUserToken(state, token){
            state.signInToken = token
            if (token) {
                localStorage.setItem(process.env.VUE_APP_LOCALSTORAGE_USER_TOKEN_KEY, token)
            } else {
                localStorage.removeItem(process.env.VUE_APP_LOCALSTORAGE_USER_TOKEN_KEY)
            }
        },
        setDrawer(state, drawer) {
            state.drawer = drawer
        }
    },
    actions: {
        async signIn({commit, dispatch}, {username, password, callback, firstLoginCallback}) {
            try {
                let data = await this._vm.$postRequest('login', '', {name: username, password})
                commit('setLoggedUser', data.data.user)
                commit('setUserToken', data.data.access_token)
                if(data.data.user.is_first_login == 1){
                    if(firstLoginCallback) firstLoginCallback()
                }
                else {
                    if (callback) callback()
                }
            }
            catch(error){
                if(error.response && error.response.status == 404)
                    dispatch('logOut')
            }
        },
        async getLoggedUserSchool({commit, dispatch, state}) {
            try {
                let data = await this._vm.$getRequest('schools', state.loggedUser.school_id)
                commit('setLoggedUserSchool', data.data.school)
            }
            catch(error){
                if(error.response && error.response.status == 404)
                    dispatch('logOut')
            }
        },
        async getLoggedUserStudent({commit, dispatch, state}) {
            try {
                let data = await this._vm.$postRequest('get_student_info', '', {
                    id: state.loggedUser.student_id
                })
                commit('setLoggedUserStudent', data.data.student)
            }
            catch(error){
                if(error.response && error.response.status == 404)
                    dispatch('logOut')
            }
        },
        logOut({commit}) {
            commit('setLoggedUser', null)
            commit('setUserToken', null)
            commit('setDrawer', false)
            router.replace({name: 'SignIn'})
        },
        switchDrawer({commit, state}) {
            commit('setDrawer', !state.drawer)
        },
    },
    getters: {
        paginationPageSizeOptions(state){
            return state.paginationPageSizeOptions
        },
        loggedUser(state) {
            return state.loggedUser
        },
        loggedUserSchool(state){
            console.log(state.loggedUser)
            if(!state.loggedUser) return null
            return state.userSchool
        },
        loggedUserStudent(state){
            if(!state.loggedUser) return null
            return state.userStudent
        },
        shouldConsiderUserLoggedIn(state){
            return state.loggedUser? state.loggedUser.is_first_login == 0 : false
        },
        isAdmin(state, getters) {
            if (!state.loggedUser) return false
            return state.loggedUser.roles.find(role=>role.id == ROLE_SUPERADMIN)
        },
        isSchool(state, getters) {
            if (!state.loggedUser) return false
            return state.loggedUser.roles.find(role=>role.id == ROLE_SCHOOL)
        },
        isStudent(state, getters) {
            if (!state.loggedUser) return false
            return state.loggedUser.roles.find(role=>role.id == ROLE_STUDENT)
        },
        isCommittee(state, getters) {
            if (!state.loggedUser) return false
            return state.loggedUser.roles.find(role=>role.id == ROLE_EDUCATION_COMMITTEE)
        }
    }
})
