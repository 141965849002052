<template>
    <div>
        <!-- Map container -->
        <l-map v-if="currentLocation" :zoom="zoom" :center="currentLocation" style="height: 400px; position: relative; z-index: 1;" @click="mapClicked">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker  :lat-lng="currentLocation"></l-marker>
        </l-map>
    </div>
</template>

<script>
    import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
    import "leaflet/dist/leaflet.css";

    import { Icon } from 'leaflet';

    delete Icon.Default.prototype._getIconUrl;
    Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    export default {
        name: 'Map',
        props:{
            initialLocation:{
                required: false
            }
        },
        components: {
            LMap,
            LTileLayer,
            LMarker,
        },
        data() {
            return {
                zoom: 15,
                center: [24.728363, 46.718577],
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                attribution:
                    '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',

                currentLocation: null
            };
        },
        methods:{
            mapClicked(event){
                this.currentLocation = event.latlng
            }
        },
        mounted(){
            this.currentLocation = this.initialLocation
            if ("geolocation" in navigator) {
                const thisComponent = this
                if(!this.currentLocation)
                {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        // The user's current location is available in the `position` object.
                        const latitude = position.coords.latitude;
                        const longitude = position.coords.longitude;

                        thisComponent.currentLocation = [latitude, longitude]
                    }, function(error) {
                        // Handle any errors that occur while getting the location
                        console.error("Error getting location:", error);
                    });
                }
            } else {
                // Geolocation is not available in this browser
                console.log("Geolocation is not available in this browser");
            }
        }
    };
</script>

<style scoped>
    #map-container {
        width: 100%;
        height: 400px;
        margin: 0;
        padding: 0;
    }
</style>
