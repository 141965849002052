<template>
    <v-container>
        <v-data-table :headers="headers" :items="hoods">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>الأحياء</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mb-2 floatedBtn" v-bind="attrs" v-on="on" v-if="isAdmin">
                                حي جديد
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                                <v-spacer />
                                <v-icon>mdi-road</v-icon>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-form ref="hoodForm" :lazy-validation="true">
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field v-model="editedItem.name" label="الاسم"
                                                    :rules="[rules.required]"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :loading="loading" color="blue floatedBtn" text @click="close">
                                    اغلاق
                                </v-btn>
                                <v-btn :loading="loading" color="blue floatedBtn" text @click="save">
                                    حفظ
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">متأكد من الحذف؟</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue floatedBtn" text @click="closeDelete">لا</v-btn>
                                <v-btn color="blue floatedBtn" text @click="deleteItemConfirm">نعم</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon v-if="isAdmin" small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon v-if="isAdmin" small @click="deleteItem(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import formValidation from "@/formValidation"
import CircularProgress from "@/components/common/CircularProgress"
import { mapGetters } from 'vuex'

export default {
    name: "hoodsList",
    data() {
        return {
            rules: {},
            dialog: false,
            dialogDelete: false,
            loading: false,
            headers: [
                {
                    text: '#',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'الاسم',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {
                    text: 'أدوات',
                    align: 'start',
                    sortable: false,
                    value: 'actions',
                }
            ],
            hoods: [],
            editedIndex: -1,
            editedItem: {
                id: -1,
                name: ''
            },
            defaultItem: {
                id: -1,
                name: ''
            },
        }
    },

    computed: {
        ...mapGetters([
            'isAdmin'
        ]),
        formTitle() {
            return this.editedIndex === -1 ? 'حي جديد' : 'تعديل حي'
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.hoods.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.hoods.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm() {
            await this.$deleteRequest('hoods', this.editedItem.id)
            this.hoods.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        async save() {
            this.rules = formValidation.rules
            if (this.$refs.hoodForm.validate()) {
                const {id, name } = this.editedItem
                if (id == -1) {
                    let data = await this.$postRequest('hoods', '', { name })
                    this.hoods.push(data.data.hood)
                } else {
                    let data = await this.$putRequest('hoods',id, {id, name })
                    Object.assign(this.hoods[this.editedIndex], data.data.hood)
                }
                this.close()
            }
        },
        async getHoods(){
            let data = await this.$getRequest('hoods', '')
            return data.data.hoods
        }
    },
    async mounted() {
        this.hoods = await this.getHoods()
    }
}
</script>

<style scoped></style>
